"use client";

import { useAuth } from "@clerk/nextjs";
import { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";

interface LandingHeroProps {
  isDevComplete: boolean;
}

export default function LandingHero({ isDevComplete }: LandingHeroProps) {
  const { isSignedIn } = useAuth();
  const [textIndex, setTextIndex] = useState(0);

  const texts = [
    "discover video podcasts with ai",
    "publish your video podcasts to get discovered",
  ];

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, 5000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="text-white font-thin py-12 sm:py-48 text-center space-y-6 sm:space-y-12 max-w-8xl mx-auto px-4 sm:px-24 flex flex-col justify-center min-h-screen">
      <h1 className="text-6xl sm:text-8xl md:text-7xl font-thin leading-none font-quicksand lowercase text-glow">
        <span className="text-8xl sm:text-[10rem] md:text-[12rem] block mb-2">melo</span>
        where ai meets i
      </h1>
      <div className="h-16 sm:h-20 flex items-center justify-center">
        <AnimatePresence mode="wait">
          <motion.p
            key={textIndex}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.5 }}
            className="text-2xl sm:text-3xl font-light absolute lowercase text-glow-subtle"
          >
            {texts[textIndex]}
          </motion.p>
        </AnimatePresence>
      </div>
    </div>
  );
}